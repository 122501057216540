<template>
  <div id="new_user" class="col-lg-12 mt-4 adacc">
    <div class="col-lg-12">
      <h3><b>Edit Episode</b></h3>
      <form class="" @submit.prevent="createSession">
        <div class="mt-4">
          <div class="row mb-3">
            <div class="col-lg-8 col-md-8">
              <!-- <label>Programme</label> -->
              <v-select class="prog" :options="programs" @input="handleSelect()" :multiple="false"
                v-model="details.session.program.name" :reduce="(name) => name.id" label="name" disabled>
                <template #search="{ attributes, events }">
                  <input class="vs__search" :required="!details.session.program.name" v-bind="attributes"
                    v-on="events" />
                </template>
              </v-select>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-lg-8 col-md-8">
              <!-- <label>Title</label> -->
              <input v-model="details.session.title" class="input-field form-control" placeholder="Name" required />
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-lg-8 col-md-8">
              <!-- <label>Start Time</label> -->
              <datetime type="datetime" :use12-hour="u12" zone="local" value-zone="local" placeholder="Date/Time"
                input-class="input-field form-control" v-model="details.session.start_at" required></datetime>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-md-8">
              <!-- <label>Description</label> -->
              <v-select :options="hosts" :multiple="false" v-model="details.session.host" :reduce="(name) => name.id"
                label="name" placeholder="Host" />
            </div>
          </div>

          <div class="row mt-3 mb-3">
            <div class="col-lg-8 col-md-8">
              <div class="uploadarea">
                <!-- <label>audio_file</label> -->

                <div v-if="isLoading" class="spinner-border" role="status">
                  <span class="visually-hidden"></span>
                </div>
                <div v-else>

                  <button type="button" class="form-btn m-0 imgbtn">
                    Choose File
                  </button>

                  <input id="primg" type="file" @change="getThumb" ref="thumbupload" accept=".mp3,audio/*" />
                  <!-- <audio controls class="ml-5 av" v-if="details.session.audio_s3_url">
                    <source :src="details.session.audio_s3_url" type="audio/mp3" />
                  </audio> -->
                  <span>
                    <audio controls class="ml-5 av" id="episodeplayer" v-if="details.session.audio_file">
                      <source :src="details.session.audio_file" type="audio/mp3" />
                    </audio>
                  </span>
                </div>
                <p id="audio_file" class="ml-3"></p>
              </div>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-md-8">
              <!-- <label>Description</label> -->
              <textarea rows="4" v-model="details.session.description" placeholder="Description"
                class="input-field form-control mt-0" required></textarea>
            </div>
          </div>
          <div class="my-3">
            <h4 class="renew d-inline">Published</h4>
            <label class="switch ml-4">
              <input type="checkbox" v-model="details.session.published" />
              <span class="slider round"></span>
            </label>
          </div>
          <router-link :to="{ path: '/admin/programmes/' + this.pro }"><button
              class="form-btn mr-4 cancel">Cancel</button></router-link>

          <button id="saveSess" type="submit" class="form-btn">
            Update Episode
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import AdminService from "../../../services/adminService";
import axios from "axios";
import router from "@/router";
import $ from "jquery";
// import { s3 } from '../../../services/aws';



export default {
  name: "EditSessionPage",
  data() {
    return {
      details: {
        session: {
          start_at: "",
          end_at: "",
          // published:true,
          // content_url: null,
          // content_host_type: null,
          program_id: null,
          audio_file: "",
          // scheme: "",
          presenter_id: "",
          title: "",
          // tag_ids: [],
          length: "",
          program: {
            name: "",
            id: "",
          },
          audio_s3_url: ""
        },
      },
      id: "",
      pro: "",
      u12: true,
      presenters: [],
      programs: [],
      schemes: [],
      content_host_types: [],
      tags: [],
      hosts: [],
      timeperiod: [
        {
          name: "15 Minutes",
          value: 900,
        },
        {
          name: "30 Minutes",
          value: 1800,
        },
        {
          name: "45 Minutes",
          value: 2700,
        },
        {
          name: "1 Hour",
          value: 3600,
        },
        {
          name: "1 Hour 15 Minutes",
          value: 4500,
        },
        {
          name: "1 Hour 30 Minutes",
          value: 5400,
        },
        {
          name: "1 Hour 45 Minutes",
          value: 6300,
        },
        {
          name: "2 Hours",
          value: 7200,
        },
      ],
      isLoading: false,
      audio_file: null
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.pro = this.$route.params.pro;
  },
  computed: {
    // foo() {
    //   return this.details.session.title === '' ? '' : this.details.session.program_id ;
    // }
  },
  watch: {
    // foo() {
    //    setTimeout(function(){
    //   var pro = $('.prog').children(".vs__dropdown-toggle").children(".vs__selected-options").children(".vs__selected")
    //   this.details.session.title=$.trim($(pro).text())
    //       }.bind(this), 500);
    // }
  },
  mounted() {
    this.getSession();
    this.getHosts();
  },
  components: {},
  methods: {
    handleSelect() {
      setTimeout(
        function () {
          var pro = $(".prog")
            .children(".vs__dropdown-toggle")
            .children(".vs__selected-options")
            .children(".vs__selected");
          this.details.session.title = $.trim($(pro).text());
        }.bind(this),
        500
      );
    },

    async createSession() {
      var alert_msg = {};
      if (this.details.session.audio_file ){
        if (typeof(this.details.session.audio_file) != 'object') {

          delete this.details.session.audio_file;
        }
      }
      axios.defaults.headers.common["Authorization"] =
      "Bearer " + this.$session.get("token");
      try {
        var bt = document.getElementById("saveSess");
        
        // alert("running")
        const response = await AdminService.updateSession(this.id, this.details);
        if (response.data.success == true) {
          // check if file
          // delete this.details.session.audio_file;
          if (this.audio_file != null) {
            this.uploadFile(response)
          }
          else {
            this.isLoading = false
            alert_msg = {
              text: "",
              head: response.data.flash.notice,
              type: "alert-success d-block",
            };
            this.$bus.$emit("flash", alert_msg);
            //bt.innerText = response.data.flash.notice
            setTimeout(function () {
              router.push({
                path:
                  "/admin/programmes/" + response.data.data.session.program.id,
              });
            }, 100);
          }

        }


        else {
          //console.log("error")
          bt.classList.add("fail");
          bt.innerText = response.data.flash.error;
          setTimeout(function () {
            bt.classList.remove("fail");
            bt.innerText = "Save Session";
          }, 1500);
          alert_msg = {
            text: response.data.errors,
            head: response.data.flash.error,
            type: "alert-danger d-block",
          };
          this.$bus.$emit("flash", alert_msg);
        }
      }
      catch (err) {
        //console.log(err)
      }
    },
    async uploadFile(response) {
      // alert("file upload")
      var alert_msg = {};
      this.details.session.audio_file = this.audio_file
      const formData = new FormData();

      // formData.append('session','')
      // if (this.audio_file) {
      formData.append('session[audio_file]', this.audio_file);
      // }   

      axios.defaults.headers.common["Content-Type"] = "multipart/form-data"
      try {
        alert("File is uploading....")
        this.isLoading = true
        const res = await AdminService.updateSession(
          response.data.data.session.id,
          formData
        );

        if (res.data.success == true) {
          //bt.classList.add("success");
          alert("File uploaded successfully")
          this.isLoading = false
          alert_msg = {
            text: "",
            head: res.data.flash.notice,
            type: "alert-success d-block",
          };
          this.$bus.$emit("flash", alert_msg);
          //bt.innerText = response.data.flash.notice
          setTimeout(function () {
            router.push({
              path:
                "/admin/programmes/" + res.data.data.session.program.id,
            });
          }, 100);
        }
        else {
          alert_msg = {
            text: "Episode updated, but error uploading file",
            head: res.data.flash.notice,
            type: "alert-warning d-block",
          };
          this.$bus.$emit("flash", alert_msg);
          setTimeout(function () {
            router.push({
              path:
                "/admin/programmes/" + res.data.data.session.program.id,
            });
          }, 100);
        }
      } catch (err) {
        //console.log(err)
      }
    },
    getThumb(event) {
      const fileSize = event.target.files[0].size / 2048 / 2048; // in MiB
      if (fileSize > 200) {
        alert("File size exceeds 200 MB");
        this.$refs.thumbupload.value = null;
      } else {
        var file = event.target.files[0]
        $("#audio_file").text(file.name)
        $(".imgbtn").text("Change File")
        this.audio_file = file
        this.details.session.audio_file=file
        $("#episodeplayer source").remove()
        $("#episodeplayer").append("<source src="+URL.createObjectURL(file)+">")

      }
    },

    async getHosts() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await AdminService.getHosts();
        if (response.data.success == true) {
          this.hosts = response.data.data;
          //  console.log(this.disable)
        } else {
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },
    async getSession() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");

      try {
        const response = await AdminService.showSession(this.id);
        if (response.data.success == true) {
          this.details.session = response.data.data.session;
          this.details.session.audio_file =
            response.data.data.session.audio_file_url;
        } else {
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },

  },
};
</script>
