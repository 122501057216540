import axios from 'axios'

// IP address of server

// staging
// production
export const SERVER_IP = process.env.VUE_APP_SERVER
    //  export const SERVER_IP = 'http://localhost:3000'
    // Port number
export const SERVER_PORT = 8080;

// server connection
export const SERVER_URL = SERVER_IP + '/api/v1/';

export default () => {
    return axios.create({
        baseURL: SERVER_URL + '',
        headers: {
            // 'Content-Type': 'application/json',
            // 'Accept': 'application/json',
            // 'Authorization':sessionStorage.getItem("token")
        }
    })
}